<template>
  <div id="PessoaCadastro">
    <!-------- FORM CADASTRO ----------------------------->
    <v-card class="px-0" v-bind:style="{ 'background-color':COR_BACKGROUND }">
      <v-card-title v-bind:style="{ 'background-color':COR_PRINCIPAL }"
        class="justify-space-between py-2 px-3">
        <span class="text-white title-page body-1">Cadastro de Agência</span>
        <v-btn
          icon
          dark
          color="#F2F6F7"
          @click="cancelar()">
          <v-icon large class="title">mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="pt-2 px-2">
        <v-form
          v-model="valid"
          ref="form"
          class="mx-1 mt-3">
          <v-row>
            <v-col
              :cols="isMobileProp ? 12 : 8"
              class="pt-3 px-2">
              <v-text-field
                v-model="agencia_numero"
                class="mt-n3 mb-0"
                loader-height="1"
                background-color="#FFF"
                label="Número *"
                placeholder="Número *"
                type="number"
                light
                filled
                outlined
                required
                dense
                :rules="numeroRules">
              </v-text-field>
            </v-col>

            <v-col
              :cols="isMobileProp ? 12 : 8"
              class="pt-0 px-2">
              <v-text-field
                v-model="agencia_numero_digito"
                class="mt-n5 mb-0"
                loader-height="1"
                background-color="#FFF"
                label="Dígito"
                placeholder="Dígito"
                type="number"
                max="1"
                light
                filled
                outlined
                required
                :rules="digitoRules"
                dense>
              </v-text-field>
            </v-col>

            <v-col
              :cols="isMobileProp ? 12 : 4"
              class="pt-0 px-2">
              <v-text-field
                v-model="agencia_nome"
                class="mt-n5 mb-0"
                loader-height="1"
                background-color="#FFF"
                label="Nome *"
                placeholder="Nome *"
                light
                filled
                outlined
                required
                dense
                :rules="nameRules">
              </v-text-field>
            </v-col>

            <v-col
              :cols="isMobileProp ? 12 : 4"
              class="pt-0 px-2">
              <v-text-field
                v-model="agencia_endereco"
                class="mt-n5 mb-0"
                loader-height="1"
                background-color="#FFF"
                label="Endereço"
                placeholder="Endereço"
                light
                filled
                outlined
                required
                dense>
              </v-text-field>
            </v-col>

            <v-col
              :cols="isMobileProp ? 12 : 4"
              class="pt-0 px-2">
              <v-text-field
              v-model="agencia_fone"
              v-mask="MASK_TELEFONE"
              class="mt-n5 mb-0"
              light
              loader-height="1"
              background-color="#FFF"
              label="Telefone"
              placeholder="(xx) xxxx-xxxx"
              filled
              outlined
              dense
              :rules="foneRules"
              ></v-text-field>
            </v-col>

            <v-col
              :cols="isMobileProp ? 12 : 4"
              class="pt-0 px-2">
              <v-text-field
                v-model="agencia_gerente"
                class="mt-n5 mb-0"
                loader-height="1"
                background-color="#FFF"
                label="Gerente"
                placeholder="Gerente"
                light
                filled
                outlined
                required
                dense>
              </v-text-field>
            </v-col>

            <v-col
              :cols="isMobileProp ? 12 : 4"
              class="pt-0 px-2">
              <v-text-field
                v-model="agencia_email"
                class="mt-n5 mb-0"
                loader-height="1"
                background-color="#FFF"
                label="E-mail"
                placeholder="E-mail"
                light
                filled
                outlined
                required
                dense
                :rules="emailRules">
              </v-text-field>
            </v-col>
          </v-row>
        </v-form>
        <!-- FIM FORM CADASTRO -->
        <v-footer 
          color="transparent"
          elevation="0"
          class="footer justify-center mt-3 pt-3">
          <v-btn
            @click="cancelar()"
            id="btn_cancelar"
            ref="btn_cancelar"
            class="mr-4 caption font-weight-medium"
            color="primary"
            text>
            Cancelar
          </v-btn>

          <v-btn 
            :disabled="!valid"
            :loading="loading"
            @click="validate()"
            class="btn caption font-weight-medium white--text"
            color="primary accent-4">
            Salvar
          </v-btn>
        </v-footer>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import store_site from "../../store/store_site";
import {
  emailIsValid,
  telefoneIsValid,
} from "../../services/funcoes";
import { COR_PRINCIPAL, 
         COR_BACKGROUND , 
         COR_SUBTITULO, 
         COR_SECUNDARIA, 
         MASK_TELEFONE } from "../../services/constantes";
import { API } from "../../services/API"

export default {
  name: "AgenciaCadastroModalMobile",

  props: {
    isMobileProp: {
      type    : Boolean,
      default : false
    },
    cod_banco: {
      type    : Number,
    },
    acao: {
      type : String
    },
    agencia_aux: {
      type : Object,
    }
  },

  components: {
  },

  data() {
    return {
      store_site            : store_site,

      agencia_numero : null,
      agencia_numero_digito : null,
      agencia_nome          : null,
      agencia_endereco      : null,
      agencia_fone          : null,
      agencia_gerente       : null,
      agencia_email         : null,

      dialog_excluir_tipo     : false,
      dialog_excluir          : false,
      dialog_excluir_registro : false,
      loading_excluir         : false,

      COR_PRINCIPAL     : COR_PRINCIPAL,
      COR_SUBTITULO     : COR_SUBTITULO,  
      COR_SECUNDARIA    : COR_SECUNDARIA,
      COR_BACKGROUND    : COR_BACKGROUND,
      MASK_TELEFONE     : MASK_TELEFONE,
    
      isMobile          : false,
      search            : null,
      
    /* Campos */
      valid                 : true,
      loading               : false,

      rules: {
        min(min, v) { 
          return (v || '').length >= min || `Mínimo de ${min} caracteres`;
        },
        max(max, v) { 
          return (v || '').length <= max || `Máximo de ${max} caracteres`;
        },
        data(v) { 
          return (v || '').length >= 10 || `Mínimo de 10 caracteres`;
        },
      },
      numeroRules: [
        (value) => !!value || "O número é obrigatório",
      ],
      digitoRules: [
        (value) =>
        ((!value || value.length <= 0) || (value && value.length < 2)) || "O dígito deve ter somente 1 número ",
      ],
      nameRules: [
        (value) => !!value || "O nome é obrigatório",
        (value) =>
          (value && value.length >= 3) ||
          "O nome deve ter no mínimo 3 caracteres",
        (value) =>
          (value && value.length <= 80) ||
          "O nome deve ter menos do que 80 caracteres",
      ],
     
      emailRules: [
        (value) => 
          ((!value || value.length <= 0) || (value && emailIsValid(value))) || "O e-mail não é válido",
        (value) =>
          (!value || value.length <= 100) || "O Email deve ter menos do que 100 caracteres",
      ],
      foneRules: [
        (value) => 
          ((!value || value.length <= 0) || (value && telefoneIsValid(value))) || "O telefone não é válido",
      ],
    };
  },

  created() {
    this.isMobile = true;
  },

  async mounted() {
    // SE TEM AGENCIA ELE VAI SE PREPARAR PARA TRAZER OS CAMPOS POPULADOS E EDITAR
    this.$nextTick(() => {
      if (!!this.agencia_aux) {
        this.agencia_numero = this.agencia_aux.agencia_numero
        this.agencia_numero_digito = this.agencia_aux.agencia_numero_digito
        this.agencia_nome = this.agencia_aux.agencia_nome
        this.agencia_endereco = this.agencia_aux.agencia_endereco
        this.agencia_fone = this.agencia_aux.agencia_fone
        this.agencia_gerente = this.agencia_aux.agencia_gerente
        this.agencia_email = this.agencia_aux.agencia_email
      } 
    })
  },

  methods: {   
    async getDevices() {
      if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
        return false;
      }
      try {
        let allDevices = await navigator.mediaDevices.enumerateDevices();
        for (let mediaDevice of allDevices) {
          if (mediaDevice.kind === "videoinput") {
            let option = {};
            option.text = mediaDevice.label;
            option.value = mediaDevice.deviceId;
            this.options.push(option);
            this.devices.push(mediaDevice);
          }
        }
        return true;
      } catch (err) {
        throw err;
      }
    },
    // EMITE UM SINAL PARA O PAI PARA QUE FECHE O DIALOG
    cancelar() {
      this.$emit('close')
    },
    //VALIDA O FORM E VERIFICA SE EDITAR OU ADICIONAR
    async validate() {
      const lb_valido = this.$refs.form.validate();
      if (lb_valido) {
        this.loading = true;

        var lo_JSON = {};
        lo_JSON.cod_banco             = this.cod_banco
        lo_JSON.agencia_numero        = this.agencia_numero
        lo_JSON.agencia_numero_digito = this.agencia_numero_digito 
        lo_JSON.agencia_nome          = this.agencia_nome
        lo_JSON.agencia_endereco      = this.agencia_endereco
        lo_JSON.agencia_fone          = this.agencia_fone
        lo_JSON.agencia_gerente       = this.agencia_gerente
        lo_JSON.agencia_email         = this.agencia_email
      
        var ls_Res;

        if (this.acao == "E") {
          ls_Res = await API.put(`/banco_agencia/${this.agencia_aux.cod_agencia}`, JSON.stringify(lo_JSON))
        }
        else{
          ls_Res = await API.post(`/banco_agencia`, JSON.stringify(lo_JSON));
        }
         
        if ((ls_Res.data.result && ls_Res.data.result.message && ls_Res.data.result.message == 'success') ||
            (ls_Res.status && ls_Res.status == 200)) {
          this.store_site.alert_cor       = "#00A000";
          this.store_site.alert_timeout   = 1500;
          this.store_site.alert_msg       = ls_Res.data.result.result.trim();
          this.store_site.alert           = true;

        } else {
          this.store_site.alert_cor       = "#A00000";
          this.store_site.alert_timeout   = 20000;
          this.store_site.alert_msg       = ls_Res.data.errors.trim();
          this.store_site.alert           = true;
        }

        this.loading = false;
        this.$emit('close')
      }
    },

    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
  },
};
</script>

<style scoped>
#PessoaCadastro {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  padding-top: 4px;
  padding-right: 8px;
  width: 100%;
}

.text-white {
  font-family: "Montserrat", sans-serif !important;
  font-size: 20px;
  color: rgba(255,255,255,0.8);
}

@media(max-width: 599px) {
  #PessoaCadastro {
    padding-right: 0px;
  }
}

/* ---------- BARRA DE SCROLL ---------- */
#PessoaCadastro::-webkit-scrollbar {
  width: 5px;
}

#PessoaCadastro::-webkit-scrollbar-button {
  padding: 1px;
}

#PessoaCadastro::-webkit-scrollbar-thumb {
  background: #CECECE; 
  border-radius: 50px;
}

#PessoaCadastro::-webkit-scrollbar-thumb:hover {
  background: #A7A7A7; 
  height: 150px;
} 

@media(max-width: 599px) {
  #PessoaCadastro::-webkit-scrollbar {
    width: initial;
  }

  #PessoaCadastro::-webkit-scrollbar-button {
    padding: initial;
  }

  #PessoaCadastro::-webkit-scrollbar-thumb {
    background: initial;
    border-radius: initial;
  }

  #PessoaCadastro::-webkit-scrollbar-thumb:hover {
    background: initial;
    height: initial;
  } 
}

.nav-link {
  text-decoration: none;
}

.btn {
  width: 100px;
}

.btn-icon {
  font-size: 17px;
}

.btn-icon-plus {
  font-size: 20px;
}

.icon-action {
  font-size: 20px!important;
  color: #9E9E9E;
}

.my-custom-dialog {
  align-self: center;
  position: absolute;
  bottom: 0;
  right: 0;
  color: red;
}

.flex-column {
  flex-direction: column!important;
}

.input-rg {
  margin-top: -18px;
}

.input-inscricao {
  margin-top: -22px;
}

@media(max-width: 599px) {
  .input-rg {
    margin-top: -8px;
  }
}

.footer {
  width: 100%;
  position: sticky;
  bottom: 0;
  padding: 2px;
  background: var(--COR_SECUNDARIA)!important;
  border-top-width: 1px!important;
  border-top-style: solid!important;
  border-top-color: var(--COR_SOMBRA)!important;
}

/* .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  padding: 0 10px;
  width: 200px !important;
} */

</style>
                
