<template>
  <v-container
    id="AgenciaConsultaModalMobile"
    class="pa-0 ma-0"
    :style="{ 'background-color':COR_BACKGROUND_TEXT }">
    <v-card>
      <!-- Cabecalho do Agencia -->
      <v-card-title :style="{ 'background-color':COR_PRINCIPAL }">
        <v-layout nowrap class="mt-n1" style="width:100%; height:28px">
          <div class="pa-0" style="width:100%">
            <p style="color:white; white-space: pre-line; font-size:16px !important;">
              {{ objectBancoAux.banco_nome }} 
            </p>                      
          </div>
          <div class="pa-0 text-center" style="width:40px;">
            <v-btn
              icon
              dark
              @click="close()"
              class="mt-n1">
              <v-icon large class="title">mdi-close</v-icon>
            </v-btn>
          </div>
        </v-layout>
      </v-card-title>
            
      <!-- Corpo do Agencia -->
      <v-card-text class="pa-0" style="height: 500px;">
        <!-- Botões de ação cabeçalho -->
        <v-toolbar-title 
          :style="{ 'background-color':COR_PRINCIPAL }"
          class="d-flex align-start" tabindex="-1"
          dark
          elevation="0">
          <v-btn 
            @click="novo()"
            class="mr-3 btn-new text-none"
            :color="COR_PRINCIPAL"
            elevation="0">
            <v-icon class="mr-1" color="green accent-2">mdi-plus</v-icon>
            <span>Novo</span>
          </v-btn>

        </v-toolbar-title>

        <!-- Help -->
        <v-expansion-panels
          class="pa-0 pt-0 px-0 expansion-panel d-none"
          accordion>
          <v-expansion-panel>
            <v-expansion-panel-header
              class="panel-header font-weight-bold"
              disable-icon-rotate>
              Documentos Básicos
              <template v-slot:actions>
                <v-icon color="" class="icon-help">
                  mdi-help-circle-outline
                </v-icon>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="pt-4">
              Conteúdo teste
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
   
        <!-- Fim Help -->
        <v-container class="pa-0 mb-16">
          <v-data-table
            v-model="selected"
            :headers="headers"
            :items="dados"
            single-select
            show-select
            fixed-header
            top="0px"
            hide-default-header
            hide-default-footer
            loading-text="Carregando...  aguarde..."
            no-data-text="Nenhum Registro Encontrado"
            no-results-text="Nenhum Registro Encontrado">
            <template #item="{ item }">
              <tr style="line-height: 30px;">
                <td class="td-height">
                  <v-checkbox
                    v-on:change="seleciona($event, item)"
                    :value="item.cod_agencia == agencia_selecionada?.cod_agencia"
                    :key="item.cod_agencia"
                    hide-details
                    class="mr-n6"
                    style="margin-top: -50%;" />
                </td>

                <td class="hide-sm py-2" style="width: 100%">
                  <span
                    style="font-size: 15px; display:block; color:#385986 !important;"
                    class="font-weight-bold">
                     {{ item.agencia_numero }} - {{ item.agencia_nome }}
                  </span>
                </td>
                  
                <td
                  class="mobile hide-sm px-0">
                  <v-btn 
                    @click="editar(item)"
                    icon dark 
                    class="btn-icon ml-n1 mr-n1"
                    color="grey">
                    <v-icon class="icon-action"> mdi-pencil </v-icon>
                  </v-btn>
                </td>
                <td
                  class="mobile hide-sm">
                  <v-btn 
                  @click="dialogExcluir(item)"
                  icon dark 
                  class="btn-icon ml-n1 mr-n1"
                  color="grey">
                    <v-icon class="icon-action"> mdi-delete </v-icon>
                  </v-btn>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-container>
      </v-card-text>
      <v-divider></v-divider>
      <!-- Rodape do Agencia -->
      <v-card-actions class="d-flex justify-center pa-3" :style="{ 'background-color':COR_SECUNDARIA }">
        <v-btn
          @click="close()"
          class="mr-4 btn text-none"
          color="primary"
          text>
          Cancelar
        </v-btn>
        <v-btn
          class="btn text-none"
          color="primary"
          :disabled="!agencia_selecionada"
          @click="selecionarAgencia()"
        >
          Selecionar
        </v-btn>
      </v-card-actions>
    </v-card>
    <!-- NOVO MODAL USANDO VUETIFY -->
    <v-container
      v-if="dialog_cadastro_agencia"
      class="px-0">
      <v-dialog
        v-model="dialog_cadastro_agencia"
        max-width="440"
        :retain-focus="false"
        persistent>
        <AgenciaCadastroModalMobile
          :cod_banco = "objectBancoAux.cod_banco"
          :agencia_aux="lo_agencia_aux"
          :acao="acao_aux"
          ref="AgenciaCadastroModalMobile"
          :isMobileProp="true"
          @close="fecharDialogCadastroAgencia"
          class="px-0"
          />
      </v-dialog>
    </v-container>
    <!-- Dialogo excluir agencia -->
    <v-container
      v-if="dialog_excluir_agencia">
      <v-dialog
        v-model="dialog_excluir_agencia"
        transition="dialog-bottom-transition"
        persistent
        max-width="410"
        class="pa-0">
        <v-divider></v-divider>
        <v-card elevation="0" class="">
          <v-card-title class="text-h6" style="word-break: keep-all">
            Tem certeza que deseja EXCLUIR esta agência?
          </v-card-title>
          <v-card-text>
            Número: <p>{{ lo_agencia_aux.agencia_numero }}</p>
            Nome: <p>{{ lo_agencia_aux.agencia_nome }}</p>
          </v-card-text>
          <v-card-actions class="d-flex justify-center">
            <v-spacer></v-spacer>
            <v-btn
              class="mr-4"
              color="primary"
              text
              @click="dialog_excluir_agencia = false">
              Cancelar
            </v-btn>
            <v-btn 
              class="btn white--text" 
              color="primary accent-4" 
              :loading="loading"
              @click="excluirAgencia()">
              Excluir
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
      <!-- Fim Dialogo excluir agencia -->
  </v-container>
</template>

<script>
import store_site from "../../store/store_site";
import { API } from "../../services/API";
import AgenciaCadastroModalMobile from "./AgenciaCadastroModalMobile.vue"
import { COR_PRINCIPAL, COR_BACKGROUND, COR_BACKGROUND_TEXT, COR_SECUNDARIA } from "../../services/constantes"

export default {
  name: "AgenciaConsultaModalMobile",

  components: {
    AgenciaCadastroModalMobile,
  },

  props: {
    editar_ :{  type    : Boolean,
                default : false,
    },
    banco_: {
      type : [Number, Object]
    },
    agencia_selecionada : {
      type : Object,
      default : () => {}
    },

    objectBancoAux : {
      type : [Object, Number],
      default : () => {}
    }
  },

  data() {
    return {
      store_site            : store_site,

      COR_PRINCIPAL         : COR_PRINCIPAL,
      COR_BACKGROUND        : COR_BACKGROUND,
      COR_BACKGROUND_TEXT   : COR_BACKGROUND_TEXT,
      COR_SECUNDARIA        : COR_SECUNDARIA,

      lb_timeout            : true,
      loading               : false,

      selected              : [],
      checked               : false,
      search_input_focus    : false,
      banco_nome_aux        : null,
      lo_agencia_aux        : null,
      acao_aux              : "I",

      dialog_cadastro_agencia   : false,
      dialog_excluir_agencia    : false,
      checkbox_marcado_aux      : false,

      dados                 : [],

      /* Título Tabela */
      headers: [
        { text: "", },
        { text: "", },
        { text: "Nome",  value: "nome", },
        { text: "Email", value: "email" },
        { text: "Telefone", value: "telefone" },
        { text: "", },
        { text: "", },
      ],
    };
  },

  mounted() {
    //BUSCA A AGENCIA ESPECIFICA
    this.busca_Agencia();
  },

  methods: {
    // FECHA O DIALOGO DE CADASTRO DA AGENCIA E BUSCA A AGENCIA PARA ATUALIZAR O DIALOGO PAI
    fecharDialogCadastroAgencia(){
      this.busca_Agencia()
      this.dialog_cadastro_agencia = false
    },
    // EMITE UM AVISO PARA QUE O COMPONENTE PAI SELECIONE A AGENCIA
    selecionarAgencia() {
      this.$emit('agenciaSelecionada', this.agencia_selecionada)
    }, 
    // FECHA O DIALOGO
    close(){
      this.$emit('close')
    },
    // EXIBE O DIALOGO PARA EXCLUIR
    dialogExcluir(item){
      this.lo_agencia_aux = item
      this.dialog_excluir_agencia = true
    },
    // EXCLUI A AGENCIA
    async excluirAgencia() {
      this.loading = true;
      const ls_Resp = await API.delete(`/banco_agencia/${this.lo_agencia_aux.cod_agencia}`)

      if (ls_Resp.status == 200){
        this.store_site.alert_cor = "green";
        this.store_site.alert_type = "success";
        this.store_site.alert_msg = ls_Resp.data.result.result;
        this.store_site.alert = true;
      } else {
        this.store_site.alert_timeout = 1500;
        this.store_site.alert_cor = "red";
        this.store_site.alert_type = "warning";
        this.store_site.alert_msg = ls_Resp.data.result;
        this.store_site.alert = true;
      }

      const resposta = await API.get("banco", { params: {cod_banco : this.objectBancoAux.cod_banco }});
      if (resposta) {
        if (resposta.data.result != 'Não possui dados') {
          this.dados = resposta.data.result[0].banco_agencia
          this.banco_nome_aux = resposta.data.result[0]?.banco_nome
        }
      }

      this.loading = false;
      this.dialog_excluir_agencia = false;
    },
   //CONTROLA O CHECKBOX PARA QUE FIQUE SOMENTE 1 MARCADO
    seleciona(evento, item) {
      if (!!item && !!evento) {
        this.checkbox_marcado_aux = !this.checkbox_marcado_aux

        this.$emit("update:agencia_selecionada", item);
      } 
      else{
        this.$emit("update:agencia_selecionada", null);
      }
    },
    // METODO PARA BUSCAR A AGENCIA E ARMAZENAR EM UM ARRAY
    async busca_Agencia() {
      this.loading = true;
      const resposta = await API.get("banco", { params: {cod_banco : this.objectBancoAux.cod_banco }});
      if (resposta) {
        if (resposta.data.result != 'Não possui dados') {
          this.dados = resposta.data.result[0].banco_agencia
          this.banco_nome_aux = resposta.data.result[0]?.banco_nome
        }
      }

      this.loading = false;
      this.lb_timeout = false;
    },
    //EXIBE O DIALOGO DE EDITAR A AGENCIA
    editar(item) {
      this.acao_aux = 'E'
      this.lo_agencia_aux = item
      this.dialog_cadastro_agencia = true;
    },
    // EXIBE O DIALOGO PARA CADASTRAR UMA AGENCIA
    novo() {
      this.acao_aux = 'I'
      this.dialog_cadastro_agencia = true;
    },
  },
};
</script>

<style scoped>
/*-----------------------------------*/
/*------ TABLE ------*/
/*-----------------------------------*/
.main-title {
  background: #0548a3;
}

.btn {
  width: 100px;
}

@media (min-width: 1024px) {
  .container-title {
    max-width: calc(100% - 32px) !important;
  }
}

.text-white {
  font-family: "Montserrat", sans-serif !important;
  font-size: 20px;
  color: rgba(255,255,255,0.8);
}

.accordion-panel {
  box-shadow: none !important;
}

.expansion-panel {
  border-radius: 8px 8px 0px 0px;
}

.v-expansion-panel::before {
  box-shadow: none !important;
}

table tr {
  width: 100%;
}

.table-row {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  background: #fff;
}

.table-row:hover {
  background: #f6f6f6 !important;
}

.panel-header {
  height: 60px;
  font-family: "Montserrat", sans-serif !important;
  font-size: 17px;
  background: #f6f6f6 !important;
  color: #4476ba;
}

.wrapper-title,
.v-toolbar,
.v-sheet {
  display: flex !important;
  flex-direction: column !important;
  padding: 0px !important;
}

.v-toolbar__content {
  padding-left: 0px !important;
}

.v-input__control {
  background: #2a63b1 !important;
}

  .search-input {
    margin: 0.8rem 10% 1.2rem 0;
    height: 35px !important;
  }

  .search-input-longo {
    margin: 0.8rem 10% 1.2rem 0;
    height: 35px !important;
    /* min-width: calc(100% - 15px); */
  }

  @media (max-width: 768px) {
    .search-input {
      margin: 0.8rem 10% 1.2rem 0;
    }
    .search-input-longo {
      margin: 0.8rem 10% 1.2rem 0;
      /* min-width: calc(100% - 15px); */

    }
  }

.v-input__control,
.v-input__slot,
.v-select__slot {
  height: 35px !important;
}

.v-label {
  margin-right: 10px !important;
}

.icon-action {
  font-size: 1.3rem !important;
}

@media (max-width: 768px) {
  .btn-icon {
    margin-right: 8px !important;
  }
}

.container-content {
  border-radius: 20px 20px !important;
  background: #fff !important;
}

@media (max-width: 768px) {
  .main-title {
    padding-right: 20px !important;
    padding-left: 20px !important;
  }

  .container-content {
    max-width: 740px !important;
  }
}

@media (max-width: 599px) {
  .container-content {
    padding: 16px !important;
  }
}

.container-table {
  max-width: calc(100% - 32px) !important;
  max-width: 400px !important;
  border-radius: 8px !important;
  background: #fff !important;
}

@media (max-width: 375px) {
  .container-table {
    max-width: 100% !important;
    border-radius: 16px !important;
  }
}

.wrapper-title div {
  padding-left: 0px !important;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  font-size: 1.5rem !important;
}

.status_ativo {
  font-size: 14px !important;
  background: #469c47;
  color: #fff;
  padding: 2px 12px;
  border-radius: 25px;
  height: 24px;
}

.status_pendente {
  font-size: 14px !important;
  background: goldenrod;
  color: #fff;
  padding: 2px 12px;
  border-radius: 25px;
  height: 24px;
}

.v-toolbar__title {
  padding-right: 0px !important;
  padding-left: 0px !important;
}

.wrapper-title {
  margin-bottom: 12px;
}

.row-container {
  /* 
  background: ;display: flex !important;
  justify-content: right !important;
  align-items: center;
  margin: 0px;
  */
}

.col-main {
  padding: 0px !important;
  margin: 0px !important;
  width: 100% !important;
}

.col-avatar {
  padding-left: 16px !important;
}

.nav-link {
  padding: 8px 0px;
}

.v-btn {
  font-size: 12px;
  padding-right: 0px !important;
  padding-left: 0px !important;
}

.btn-new {
  font-size: 14px;
  color: white;
  width: 100px;
}

.td-height {
  min-height: 60px !important;
}


</style>